

import React, { useState, useEffect } from 'react';

function MicrophoneRequest() {
  const [hasPermission, setHasPermission] = useState(null);

  useEffect(() => {
    // Kiểm tra quyền truy cập micro khi component được mount
    navigator.permissions.query({ name: 'microphone' }).then(permissionStatus => {
      setHasPermission(permissionStatus.state === 'granted');
      permissionStatus.onchange = () => {
        setHasPermission(permissionStatus.state === 'granted');
      };
    });
  }, []);

  const requestMicrophoneAccess = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => setHasPermission(true))
      .catch(() => setHasPermission(false));
  };

  return (
    <div>
      {hasPermission === null && <p>Đang kiểm tra quyền truy cập micro...</p>}
      {hasPermission === false && (
        <button onClick={requestMicrophoneAccess}>Yêu cầu quyền truy cập micro</button>
      )}
      {hasPermission && <p>Bạn đã cấp quyền truy cập micro.</p>}
    </div>
  );
}

export default MicrophoneRequest;
