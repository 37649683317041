import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Row, Avatar } from "antd";
import { EditFilled, DeleteFilled, UserOutlined } from "@ant-design/icons";

import Edit from "./Edit";
import Delete from "./Delete";

export default function ShowTable({
  data,
  setRefressSearch,
  departmentSelected,
  exten,
  agentStatus,
}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  console.log("[ShowTable]:departmentSelected=", departmentSelected);
  
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);
  // console.log("[ShowTable]:data=",data);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [dataCount, setDataCount] = useState({
    nUnavailable: 0,
    nonline: 0,
    nIncall: 0,
    nNotinuseNotPaused: 0,
    nNotinusePaused: 0,
    nPaused: 0,
  });
  const handleEdit = (item) => {
    console.log("[handleEdit]:item=", item);
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const handleRemove = (item) => {
    console.log("[handleRemove]:item=", item);
    setVisibleRemove(true);
    setSelectedDeleteItem(item);
  };

  const onCloseEdit = () => {
    console.log("[onCloseEdit]");
    setSelectedItem(null);
    setVisibleEdit(false);
  };
  const onCloseRemove = () => {
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
  };

  useEffect(() => {
    let a = {
      nUnavailable: 0,
      nonline: 0,
      nIncall: 0,
      nNotinuseNotPaused: 0,
      nNotinusePaused: 0,
      nPaused: 0,
    };
    console.log({a,dataCount});
    Object.entries(data).map(([k, item]) => {
      if (
        item.department_id == "0" ||
        !departmentSelected.includes(+item.department_id)
      ) {
        // if (item.department_id=='0' || ((item.department_id!=departmentSelected) && (departmentSelected!==''))) {
          setDataCount({ ...a });
        return;
      }
      if (item.status == 5) a.nUnavailable++;
      if (item.status != 5 && item.status != 4) a.nonline++;
      if (item.status == 2 || item.status == 3 || item.status == 6) a.nIncall++;
      if (item.status === "1" && item.paused === "0") a.nNotinuseNotPaused++;
      if (item.status === "1" && item.paused == "1") a.nNotinusePaused++;
      if (item.paused == "1") a.nPaused++;

      setDataCount({ ...a });
    });
  }, [data, departmentSelected]);
  const isShowAgent = (item) => {
    let kq = false;
    // if ((item.department_id=='0') || (item.department_id!=departmentSelected) && (departmentSelected!=='')) {
    //    kq=false;
    //    return kq;
    // }
    if (
      item.department_id == "0" ||
      !departmentSelected.includes(+item.department_id)
    ) {
      kq = false;
      return kq;
    }
    if (exten) {
      if (exten !== item.ext) {
        kq = false;
        // console.log("[isShowAgent]:item.ext=",item.ext);
        // console.log("[isShowAgent]:exten?.value=",exten);
        return kq;
      }
    }
    switch (agentStatus) {
      case "5":
        if (item.status == 5) {
          kq = true;
          // setDataCount(pre=>({...pre,nUnavailable:pre.nUnavailable++}))
        }
        break;
      case "Online":
        if (item.status != 5) kq = true;
        break;
      case "Incall":
        if (item.status == 2 || item.status == 3 || item.status == 6) kq = true;
        break;
      case "Idle":
        if (item.status === "1" && item.paused === "0") kq = true;
        break;
      case "IdlePaused":
        if (item.status === "1" && item.paused == "1") kq = true;
        break;
      case "Paused":
        if (item.paused == "1") kq = true;
        break;
      default:
        kq = true;
        break;
    }
    return kq;
  };
  return (
    <>
      <div className="mycontainer">
        <div className="numberAgentStatus">
          <ul>
            <li>
              <span>Unavailable:</span>
              {dataCount["nUnavailable"]}
            </li>
            <li>
              <span>Online:</span>
              {dataCount["nonline"]}
            </li>
            <li>
              <span>InUsed:</span>
              {dataCount["nIncall"]}
            </li>
            {/* <li><span>Idle:</span>{data['nNotinuse']}</li> */}
            <li>
              <span>Idle:</span>
              {dataCount["nNotinuseNotPaused"]}
            </li>
            <li>
              <span>Idle | Paused:</span>
              {dataCount["nNotinusePaused"]}
            </li>

            <li>
              <span>Paused:</span>
              {dataCount["nPaused"]}
            </li>

            {/* <li><span>Unknow:</span>{data['nUnknow']}</li> */}
          </ul>
        </div>

        <div className="flexrow">
          {Object.entries(data).map(
            ([k, item]) =>
              isShowAgent(item) && (
                <span key={k} className="agentStatusItem">
                  <div>
                    <Avatar
                      className={item.className}
                      icon={<UserOutlined />}
                    />
                  </div>
                  <div>
                    <span style={{ color: "blue" }}>
                      {item.ext}-{item.name}{" "}
                      {item.department_name ? ` | ${item.department_name}` : ""}
                    </span>{" "}
                    <br />
                    <span className="subTitle">{item.statusName}</span>
                  </div>
                </span>
              )
          )}
        </div>
      </div>
      <br />
      <Drawer
        title="Edit  Menu Item"
        placement="right"
        onClose={onCloseEdit}
        visible={visibleEdit}
      >
        {selectedItem && (
          <Edit
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            setRefressSearch={setRefressSearch}
            setVisibleEdit={setVisibleEdit}
          />
        )}
      </Drawer>

      <Drawer
        title="Remove  Menu Item"
        placement="right"
        onClose={onCloseRemove}
        visible={visibleRemove}
      >
        {selectedDeleteItem && (
          <Delete
            item={selectedDeleteItem}
            setRefressSearch={setRefressSearch}
            setVisibleRemove={setVisibleRemove}
          />
        )}
      </Drawer>
    </>
  );
}
