
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button, Input, Form, Select, message } from 'antd';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';



import useAxios from './../../CustomHooks/useAxios';
import DebounceSelect from './../DebounceSelect'
const { Option } = Select;


export default function EditAgents({ item, setSelectedItem, setVisibleEdit, setRefreshSearch }) {

  const { axiosi } = useAxios();

  const [companyid, setCompanyid] = React.useState({ value: item.company_id, label: item.company_name });
  const [activestatus, setActivestatus] = React.useState(item.activestatus);
  const [authorization_groups, setAuthorization_groups] = useState(null);
  const [authorization_groupsLoading, setAuthorization_groupsLoading] = useState(true);
  const [authorization_group_id, setAuthorization_group_id] = useState("");
  const [enablecallout, setEnablecallout] = useState(item.enablecallout);


  const [teloutrule, setTeloutrule] = useState(item?.teloutrule);

  const [department_idData, setDepartment_idData] = useState(null);
  const [department_id, setDepartment_id] = useState(item?.department_id);


  const [departOptions, setDepartOptions] = useState([])
  const [viewCallLogsDepart, setViewCallLogsDepart] = useState(JSON.parse(item?.logdepartments));
  const [log_agent_status_deps, setLog_agent_status_deps] = useState(JSON.parse(item?.log_agent_status_deps));

  const [telsoutDataLoading, setTelsoutDataLoading] = useState(false);

  const [telout, setTelout] = useState(item?.tels_id);//
  const [telsoutData, setTelsoutData] = useState(null);
  const [callRuleData, setCallRuleData] = useState(null);
  const [call1Rule, setCall1Rule] = useState(item?.call1rule);
  const [call2Rule, setCall2Rule] = useState(item?.call2rule);
  const [showCallout, setShowCallout] = useState((item?.teloutrule!=="calloutrule"));
  const [showCallTelout, setShowTelout] = useState((item?.teloutrule!==""));


  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [webrtc, setWebrtc] = useState(item.webrtc);
  const [extVisible, setExtVisible] = useState(() => item.webrtc === "no");
  
  function handleOnChangeCalloutRule(value){
    console.log({value,showCallout,hideItem});
    
    setTeloutrule(value);
    setShowCallout(value!=="calloutrule");
    setShowTelout(value!=="");
  }
  useEffect(() => {
    const getAuthorizationGroups = async () => {
      const kq = await axiosi.get('authorization_groups', { params: { pageSize: 200 } });
      console.log("[MenuItem-useEffect]:kq.data=", kq.data)
      setAuthorization_groups(kq.data);
      setAuthorization_groupsLoading(false);
    }
    getAuthorizationGroups();
  }, [])


  useEffect(() => {
    const getTelout = async () => {
      const kq = await axiosi.get('tels', { params: { companyid: item.company_id, pageSize: 100 } });
      console.log("[getTelout]:kq.data=", kq);
      // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
      setTelsoutData(kq.data);
      setTelout(item?.tels_id);
    }
    getTelout();
  }, [item?.company_id])

  useEffect(() => {
    const getCallRuleData = async () => {
      const kq = await axiosi.get('tels_callout_rule', { params: { companyid: item.company_id, pageSize: 100 } });
      console.log("[getCallRuleData]:kq.data=", kq.data);
      // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
      setCallRuleData(kq.data);
      // setTelout(item?.tels_id);
    }
    getCallRuleData();
  }, [item?.company_id])

  useEffect(() => {
    const getDepartment = async () => {
      const kq = await axiosi.get('department', { params: { company_id: item.company_id, pageSize: 100 } });
      console.log("[getDepartment]:kq=", kq);

      setDepartment_idData(kq.data);
      setDepartment_id(item?.department_id);
      kq?.data?.rows.forEach(dep => {
        setDepartOptions((pre) => [...pre, {
          label: dep.name,
          value: dep.id,

        }])
      });
    }
    getDepartment();
  }, [item?.company_id])

  async function fetchUserList(company_name) {

    console.log('fetching user', company_name);
    const body = await axiosi.get('companies', { params: { company_name, pageSize: 100 } });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
        //  value:{id: user.id,company_code:user.company_code},
        company_code: user.company_code,
      }));
    }
    else {
      const a = [{ label: "---no data---", value: "" }]
      return a.map(i => (i))
    }

  }
  function isStrongPwd1(password) {
    var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
    var validPassword = regExp.test(password);
    return validPassword;
  }
  const onFinishAdd = async (values) => {
    // console.log("[onFinishAdd]:values=",values);
    // console.log("[onFinishAdd]:item=",item);
    // console.log("[onFinishAdd]:companyid=",companyid);
    // console.log("[onFinishAdd]:telsoutData=",telsoutData);
    // console.log("[onFinishAdd]:telout=",telout);
    const teloutSelected = telsoutData?.rows?.find((item) => item.id === telout)
    // console.log("[onFinishAdd]:teloutSelected=",teloutSelected);
    // console.log("[onFinishAdd]:datatosave=",{...values,company_id:companyid.value
    //   ,tels_id:telout
    //   ,telout_num:teloutSelected?.telout
    //   ,telout_peer:teloutSelected?.peername
    // ,endpoint_id:values.ep_username})

    if (!isStrongPwd1(values.password)) {
      message.info("The Agent password must have atleast 8 chars with one uppercase letter, one lower case letter, one digit and one of !@#$%*()");
      return;
    }
    if ((webrtc === 'no') && !isStrongPwd1(values.ep_password)) {
      message.info("The Ext password must have atleast 8 chars with one uppercase letter, one lower case letter, one digit and one of !@#$%*()");
      return;
    }
    // const newAgent={...values,
    //   // name:values.name,
    //   // username,
    //   password,
    //   authorization_group_id,
    //   endpoint_id,
    //   company_id,
    //   telout_num,
    //   telout_peer,
    //   max_contacts,
    //   ep_username,
    //   ep_password,
    //   note,
    //   tel,
    //   email,
    //   webrtc,
    // }
    // return;
    console.log({ department_id, enablecallout, teloutrule });
    try {
      const kq = await axiosi.put(`agents/${item.id}`, {
        ...values, company_id: companyid.value
        ,call1rule:call1Rule
        ,call2rule:call2Rule
        , tels_id: telout
        , telout_num: teloutSelected?.telout
        , telout_peer: teloutSelected?.peername
        , endpoint_id: values.ep_username,
        webrtc
        , activestatus
        , department_id, enablecallout, teloutrule
        , logdepartments: JSON.stringify(viewCallLogsDepart)
        , log_agent_status_deps: JSON.stringify(log_agent_status_deps)
      });
      console.log("[onFinishAdd]:kq=", kq);
      setVisibleEdit(false);
      setSelectedItem(null);
      setRefreshSearch(pre => !pre);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error(`Error: ${error.response.data.code}`);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    }
  }
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  }


  const handleOnChangeCompanyName = async (value) => {
    setCompanyid(value);
    const kq = await axiosi.get('tels', { params: { companyid: value.value, pageSize: 100 } });
    console.log("[handleOnChangeCompanyName]:kq_get_tels=", kq);
    console.log("[handleOnChangeCompanyName]:selectedvalue=", value);
    setTelsoutData(kq.data);
    setTelout("");
  }
  const handleOnChangeTelsout = async (value) => {
    setTelout(value);
    console.log(value);
  }
  const handleOnChangeCall1Rule = async (value) => {
    setCall1Rule(value);
    console.log(value);
  }
  const handleOnChangeCall2Rule = async (value) => {
    setCall2Rule(value);
    console.log(value);
  }
  const handleOnChangeWebRTC = (value) => {
    // console.log("[handleOnChangeWebRTC]",{value});
    setWebrtc(value);
    setExtVisible(value === "no");
  }
  // if (1)return (<h1>hello</h1>)

  const handleOnChangeViewCallLogDepart = (value) => {
    // console.log("[handleOnChangeViewCallLogDepart]:viewCallLogsDepart=",viewCallLogsDepart);
    // console.log("[handleOnChangeViewCallLogDepart]:selected=",value);
    setViewCallLogsDepart(value);
  }
  const handleOnChangeViewAgentStatusDepart = (value) => {    
    setLog_agent_status_deps(value);
  }
  return (<>
    <Form
      name="basic"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      initialValues={item}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: true, message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          disabled
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}
          // {(newValue) => {setCompanyid(newValue);}}
          style={{ width: '100%', }}
        />
      </Form.Item>
      <Form.Item label="Author group" name="authorization_group_id"
        rules={[{ required: true, message: 'Please input an Authoriztion group!' }]}
      >
        <Select style={{ width: '100%' }}
          onChange={(value) => setAuthorization_group_id(value)}
          loading={authorization_groupsLoading}

        >
          <Option value=""></Option>
          {authorization_groups?.rows && authorization_groups.rows.map(item => (
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>



      <Form.Item
        label="Department"
      >
        {department_idData?.rows && <>
          <Select style={{ width: '100%' }}
            // onChange={handleOnChangeTelsout}
            onChange={(value) => setDepartment_id(value)}
            //(value)=>setMenu_item_id(value)}
            // loading={menuItemDataLoading}
            value={department_id}

          >
            <Option value=""></Option>
            {department_idData?.rows && department_idData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>

        </>}
      </Form.Item>

      <Form.Item
        label="View call logs of Department"
      >
        {department_idData?.rows && <>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select"
            defaultValue={JSON.parse(item?.logdepartments)}

            options={departOptions}
            onChange={handleOnChangeViewCallLogDepart}
          />

        </>}
      </Form.Item>

      <Form.Item
        label="View AgentStatus of Department"
      >
        {department_idData?.rows && <>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select"
            defaultValue={JSON.parse(item?.log_agent_status_deps)}

            options={departOptions}
            onChange={handleOnChangeViewAgentStatusDepart}
          />

        </>}
      </Form.Item>


      <Form.Item
        label="Agent Name :"
        name="name"
        rules={[{ required: true, message: 'Please input an Agent name!' }]}
      >
        <Input />

      </Form.Item>


      <Form.Item
        label="Agent Username :"
        name="username"
        rules={[{ required: true, message: 'Please input an Agent Username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Agent Password :"
        name="password"
        rules={[{ required: true, message: 'Please input an Agent password!' }]}
      >
        <Input.Password placeholder="input Agent password"
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
      </Form.Item>


      <Form.Item
        label="webrtc :"
      // name="webrtc"
      // rules={[{ required: true, message: 'Please input an webrtc!' }]}
      >
        <Select onChange={handleOnChangeWebRTC} value={webrtc}>
          <Option value="no">no</Option>
          <Option value="yes" >yes</Option>
        </Select>
      </Form.Item>

      {/* <Form.Item
        label="Ext number :"
        name="ep_username"
        rules={[{ required: true, message: 'Please input an ext number!' }]}
      >
        <Input  />
        
      </Form.Item> */}

      <Form.Item className={hideItem}
        label="Ext username :"
        name="ep_username"
        rules={[{ required: true, message: 'Please input an ext username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Ext password :"
        name="ep_password"
        hidden={!extVisible}
        rules={[{ required: true, message: 'Please input an ext password!' }]}
      >
        <Input.Password placeholder="input ext password" />
      </Form.Item>
      <Form.Item className={hideItem}
        label="max_contacts :"
        name="max_contacts"
        rules={[{ required: true, message: 'Please input an max_contacts!' }]}
      >
        <Input type="number" />
      </Form.Item>


      

      <Form.Item
        label="Enable Callout :"
        // name="dep"
        rules={[{ required: false, message: 'Please input an department!' }]}
      >
        <Select
          // defaultValue="0"
          value={enablecallout} onChange={(value) => setEnablecallout(value)}>
          <Option value={1}>Enable</Option>
          <Option value={0} >Disable</Option>
        </Select>
      </Form.Item>




      <Form.Item className={hideItem}
        label="Callout Rule :"
        // name="dep"
        rules={[{ required: false, message: 'Please input an department!' }]}
      >
        <Select onChange={(value) => handleOnChangeCalloutRule(value)} value={teloutrule}>
          <Option value="">fixed</Option>
          <Option value="random">Random</Option>
          <Option value="lowestcode" >Lowest cost</Option>
          <Option value="calloutrule" >Company Callout Rule</Option>
        </Select>
      </Form.Item>

      
      <Form.Item

        label="telout"
        name1="telout"
        // hidden={hideItem === "hideItem"}
        hidden={showCallTelout}
      >
        {telsoutData?.rows && <>
          <Select style={{ width: '100%' }}
            onChange={handleOnChangeTelsout}
            //(value)=>setMenu_item_id(value)}
            // loading={menuItemDataLoading}
            value={telout}

          >
            <Option value=""></Option>
            {telsoutData?.rows && telsoutData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.telout}</Option>
            ))}
          </Select>

        </>}
        {/* {!telsoutData?.rows && <Input/>} */}
      </Form.Item>

      <Form.Item

        label="Call1 rule"
        name1="telout"
        // hidden={(hideItem === "hideItem")&&showCallout}
        hidden={showCallout}
      >
        {callRuleData?.rows && <>
          <Select style={{ width: '100%' }}
            onChange={handleOnChangeCall1Rule}
            value={call1Rule}

          >
            <Option key={0} value={0}> </Option>
            {callRuleData?.rows && callRuleData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>

        </>}

      </Form.Item>

      <Form.Item

        label="Call2 rule"
        name1="telout"
        hidden={showCallout}
      >
        {callRuleData?.rows && <>
          <Select style={{ width: '100%' }}
            onChange={handleOnChangeCall2Rule}
            value={call2Rule}

          >
            <Option key={0} value={0}> </Option>
            {callRuleData?.rows && callRuleData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>

        </>}

      </Form.Item>

      <Form.Item
        label="Agent email :"
        name="email"
      // rules={[{ required: true, message: 'Please input an email!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Agent tel :"
        name="tel"
      // rules={[{ required: true, message: 'Please input an email!' }]}
      >
        <Input />
      </Form.Item>


      <Form.Item className={hideItem}
        label="Active :"
      // name="activestatus"
      // rules={[{ required: true, message: 'Please input an webrtc!' }]}
      >
        <Select
          // defaultValue="0"
          value={activestatus} onChange={(value) => setActivestatus(value)}>
          <Option value={1}>Active</Option>
          <Option value={0} >Deactive</Option>
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>



  </>);
}
