import {useState,useEffect} from 'react';
import {  useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import EditTels from "./EditTels";
import DeleteTels from "./DeleteTels";
import CallTo from './../CallTo'
export default function ShowTelsTable({data,setRefreshSearch}) {
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);

    const user = useSelector((state) => state.loginReducer.user);
  const [hideItem,setHideItem]=useState('');

  useEffect(()=>{
    if (user?.agents) {
      if(user?.agents[0].company_id!==1) {          
        setHideItem('hideItem');          
      } else {          
        setHideItem('col');
      }      
    }
},[user?.agents])

    const handleEdit=(item)=>{
      console.log("[handleEdit]:item=",item);
      setSelectedItem(item);
      setVisibleEdit(true);
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}
return (
  <>
      <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <EditTels item={selectedItem} 
              setRefreshSearch={setRefreshSearch}
              setVisibleEdit={setVisibleEdit}
              setSelectedItem={setSelectedItem}
            />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <DeleteTels item={selectedDeleteItem} 
              setRefreshSearch={setRefreshSearch}
              setVisibleRemove={setVisibleRemove}
          />}        
      </Drawer>

      <table className='table1'>
  <thead>
  <tr>
      {/* <th>id</th> */}
      {/* <th>calldirection</th>
      <th>connected</th> */}
      
      <th width="220px" className={hideItem}>company_name</th>
      
      <th className={hideItem} width="320px">itsp</th>
      
      <th >telin</th>
      <th >telout</th>
      
      <th>ivrtype</th>
      <th>Call to ITSP</th>
      <th>Note</th>
      
      
      
      <th width="120px">Action</th>
      

  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      {/* <td>{item.id}</td> */}
      {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}
      
      
      <td className={hideItem}>{item.company_name}</td>
      
      <td className={hideItem} width={200}>N: {item.itspname} <br/>
                               P: {item.peername}</td>
      
      <td width={300}><CallTo callTo= {`testivr_${item.telin}`} txt={item.telin} /> </td>
          
      <td>{item.telout}</td>
      <td>{item.ivrtype===0?"Company":"Telin"}</td>
      <td>{item.foritsp}</td>
      <td>contactname: {item.contactname} <br/>
          note : {item.note}</td>
      
      
      
      <td> 
         {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
         <div className='rowFlex'>
             <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
              <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>
         </div>
         {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
      </td>
      
  </tr>
))}
</tbody>
</table>
  </>
)
}