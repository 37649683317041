import React,{useEffect,useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { Button,Card,Col,Row ,message } from 'antd';

import { PhoneOutlined ,CopyOutlined,PauseOutlined,SwapOutlined,CaretRightOutlined,AudioMutedOutlined ,AudioOutlined} from '@ant-design/icons';
import { Routes, Route, Link, useLocation, useMatch } from "react-router-dom";
import Draggable from 'react-draggable';
import Numpad from './Numpad';
import moment from 'moment';
import useAxios from './../CustomHooks/useAxios';
import TiketNoteIn from './TicketNoteIn';

import "./Call.css";
import {    
  addCallInfo_Ticket,
} from "./../redux/jsSIPSlice";

export default function CallIn({id,mysession,mysessionInfo,setMySessionsIdTicket}) {
  console.log("[CallIn]:",{id,mysession,mysessionInfo});
  const {axiosi}=useAxios();
  const dispatch = useDispatch();
  
  const [displayKeypad,setDisplayKeypad]=useState(false);
  const [customerInfo,setCustomerInfo]=useState(null);
    const [priority_level,setPriority_level]=useState('');
    const [tel,setTel]=useState('');
    const audioPlayers = useSelector(state => state.jsSIPReducer.audioPlayers)
    console.log("[CallIn]:",{id,mysession,mysessionInfo});
  const [customerId,setCustomerId]=useState('');
  const [isOpenTicket,setIsOpenTicket]=useState(false)
  const [callStatus,setCallStatus]=useState('Ringing...');
  const [counttime,setCounttime]=useState(0);
  const [loop, setLoop] = useState();
  const doOpenTiket=()=>{
    // setMySessionsIdTicket((prev)=>([...prev,id]));
    setIsOpenTicket(true);
    dispatch(addCallInfo_Ticket({ id, call_id: mysessionInfo.call_id,tel:mysessionInfo?.tel,mysessionInfo,customerInfo }));
    setMySessionsIdTicket((prev)=>([...prev,id]));
  }  
  useEffect(()=>{
      const getCustomerInfoByTel=async(tel)=>{
          try{
          const kq=await axiosi.get("comcontacts/tel",{params:{tel}});
          const kqdata= await kq.data;
          console.log("[getCustomerInfoByTel]:kqdata=",kqdata);
          console.log("[getCustomerInfoByTel]:kqdata.rows=",kqdata.rows);
          
          if (kqdata.rows.length>0){
              setCustomerInfo(kqdata.rows);
              setCustomerId(kqdata.rows[0]['id']);
              setPriority_level(` (Priority: ${kqdata.rows[0].priority_level})`);
              
          } else {
              setCustomerInfo(null);
              setCustomerId('');
              setPriority_level("");
          }
          
          }catch (error){
              if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log("error.response.data=",error.response.data);
                  console.log("error.response.status=",error.response.status);
                  console.log("error.response.headers=",error.response.headers);
                  // message.error(`Error: ${error.response.data.code}`);
                  setCustomerInfo(null);
              setPriority_level("");
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log(error.request);
                  setCustomerInfo(null);
              setPriority_level("");
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                  setCustomerInfo(null);
              setPriority_level("");
                }
                console.log(error.config);
                setCustomerInfo(null);
              setPriority_level("");
          }

      }
      if (mysessionInfo?.tel==="asterisk") {
        const aa=mysessionInfo?.display_name.split("amiOK-")
        if (aa.length>1){
          setTel(aa[1]);
          getCustomerInfoByTel(aa[1]);
          // handleHangup();
        } else {
          setTel(mysessionInfo?.tel);
          getCustomerInfoByTel(mysessionInfo?.tel);
        }
        
      } else {
        setTel(mysessionInfo?.tel);
        getCustomerInfoByTel(mysessionInfo?.tel);
      }
      
  },[mysessionInfo?.tel,mysessionInfo?.display_name])
    const handleHangup=()=>{
        mysession.terminate();
        console.log("jssipCall_Hangup");
        audioPlayers.Ring.pause();
    }
    
    function rdDuration(d) {
      if (d>0) {
        const idtime = new Date(d * 1000)
        .toISOString()
        .substr(11, 8);
      return "Connected: " + idtime;
      } else {
        return "Call status: "+callStatus;
      }
      
    }
    useEffect (()=>{

      mysession.on("accepted", (data) => {
        console.log("[Callin.js - mysessions.current[e.session.id].on = confirmed]:data=",data);
        setCallStatus("Connected");

        setLoop(
          setInterval(() => {
            console.log("loading");
            setCounttime(pre=>pre+1);
            // count();
          }, 1000)
        );

      })

      return function cleanup() {
        console.log("cleaning up");
        clearInterval(loop);
      };

    },[mysession,loop]);

    const  handleAnswer=()=> {
        console.log("handleAnswer");
            // Register callbacks to desired call events
            var eventHandlers = {
              progress: function (e) {
                console.log("call is in progress", e);
                debugger;
              },
              failed: function (e) {
                audioPlayers.Ring.pause();
                console.log("[eventHandlers]:call failed with cause: ", e);
                debugger;
                
              },
              ended: function (e) {
                audioPlayers.Ring.pause();
                console.log("[eventHandlers]:call ended with cause: ", e);
                debugger;
                if (e.originator === "remote") {
                  console.log('[eventHandlers-failed_]:remote-> playEND');
                  audioPlayers.End.play();
                  setTimeout(()=>{audioPlayers.End.pause();}, 1500);
                }
              },
              confirmed: function (e) {
                console.log("call confirmed", e);
                debugger;
              },
              peerconnection: function (e) {
                console.log("peerconnection", e);
                //   var peerconnection = e.peerconnection;
                //   console.log(peerconnection);
                //   peerconnection.onaddstream;
                //   debugger;
              },
            };
          
            var options = {
              eventHandlers: eventHandlers,
              mediaConstraints: { audio: true, video: false },
            };
          
            audioPlayers.Ring.pause();
            mysession.answer(options);
            setDisplayKeypad(true);
            mysession.connection.addEventListener("addstream", function (e1) {
              // Or addtrack
            //   debugger;
              var remoteAudio = window.document.createElement("audio");
              window.document.body.appendChild(remoteAudio);
              remoteAudio.srcObject = e1.stream;
              remoteAudio.play();
          
              // const remoteAudio = document.createElement("audio");
              // remoteAudio.srcObject = window.URL.createObjectURL(e1.stream);
              // remoteAudio.play();
            });
          
            // debugger;
          }
          const displayCustomer=()=>{
            // console.log("[displayCustomer]:customerInfo=",customerInfo);
            let or='';
            let i=0;
           return customerInfo.map(item=>{
             i++;
             if (i>1) {
              or=( <h3>Or </h3>);
             }
             const a=(        
               <div key={item.id}>{or}     
              <ul key={item.id}>
            
                  <li>Name : {item.name}</li>
                  <li>Company : {item.ccomname}</li>
                  <li>Gender : {item.gender}</li>
                  <li>Birthday : {item.dob && moment(item.dob).format('YYYY-MM-DD')}</li> 
                  <li>Tel : {item.tel} <span className='CopyColor' title='Click to Copy !' onClick={()=>handleCopy(item.tel)}><CopyOutlined /></span></li>
                  <li>Note : {item.note}</li>
      
              </ul>
              </div>
            );
             switch(i){
               case 1: return a;
              //  case 2: return a;
               default: return (<div key={item.id}>
               <h3>Or </h3>
               <ul key={item.id}>
            
                <li>Name : {item.name}</li>
                <li>Company : {item.ccomname}</li>
                <li>...</li>
                
      
            </ul></div>);
             }
              
             
          })
          }
          const handleCopy=(value)=>{
            navigator.clipboard.writeText(value);
            message.info({
                content: 'copied !',
                className: 'custom-class',
                style: {
                  position: "absolute",
                  top:"-15px",
                  right:"1px",
                },
              });
        }
    return (
      <Draggable
        // axis="x"
        handle=".moveable .ant-card-head"
        defaultPosition={{x: 0, y: 0}}
        position={null}
        grid={[25, 25]}
        scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
        >
        <div className="site-card-border-less-wrapper popupCallBox moveable">
           <Card title={`Call from : ${tel} ${priority_level}`} bordered={false} style={{ width: 300 }}>
           {displayKeypad &&  <Numpad callStatus={rdDuration(counttime)}  mysession={mysession}  handleHangup={handleHangup}/>}
           {(!customerInfo) && <h2>{mysessionInfo?.tel}-NewCustomer <span className='CopyColor' title='Click to Copy !' onClick={()=>handleCopy(mysessionInfo?.tel)}><CopyOutlined /></span></h2>}
           <div style={{"overflow":"auto","max-height":"300px"}}>
           {customerInfo?.length===1 && (
            
            <ul>
         
                <li>Name : {customerInfo[0].name}</li>
                <li>Company : {customerInfo[0].ccomname}</li>
                <li>Gender : {customerInfo[0].gender}</li>
                <li>Birthday : {customerInfo[0].dob && moment(customerInfo[0].dob).format('YYYY-MM-DD')}</li>                
                <li>Tel : {customerInfo[0].tel}<span className='CopyColor' title='Click to Copy !' onClick={()=>handleCopy(customerInfo[0].tel)}><CopyOutlined /></span></li>
                <li>Note : {customerInfo[0].note}</li>

            </ul>
            )}
            {customerInfo?.length>1 && displayCustomer()}
            

            </div>
           
            
            
            
            {!displayKeypad && (
         <div className="AnswerGroup">   
            <Button  onClick={handleHangup} style={{marginRight: "10px"}} type="primary" danger ghost> Reject <PhoneOutlined/></Button>
            <Button onClick={handleAnswer} type="primary"  ghost >Answer<PhoneOutlined/></Button>
            
        </div>
        )}
          
    </Card>
    {!isOpenTicket && <Button type="link"  onClick={doOpenTiket}>Open a Ticket</Button> }
    {/* <Link to={"/tickets?tel="+tel+"&cusid="+customerId}>
                                          {"tickets"}
                                        </Link>     */}
        </div>
        
        </Draggable>
    )
}
