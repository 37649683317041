import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {message, Drawer,Input, Select, Space, Cascader,Button ,Typography, Divider,Checkbox,Tabs,Form } from 'antd';
import {CloseSquareFilled}  from '@ant-design/icons';
import Draggable from 'react-draggable';
import useAxios from './../CustomHooks/useAxios';
import {    
    delCallInfo_Ticket,
  } from "./../redux/jsSIPSlice";
//   import Add from "./Customers/Contacts/Add";
  import AddCustomer from "./TicketNote/AddCustomer";
  import EditCustomer from "./TicketNote/EditCustomer";
  import SwitchCustomer from "./Customers/Contacts/SelectaContact";

  import ShowCallLogTable from "./CallLog/ShowCallLogTable";

  import ShowTicketNoteTable from "./Tickets/Tickets/ShowTable";

const { Option } = Select;
const { TabPane } = Tabs;
export default function TicketNoteIn({id,setMySessionsIdTicket,provinceData}) {
    console.log("[TicketNote]: provinceData=",provinceData);
    const dispatch = useDispatch();
    const nodeRef = React.useRef(null);
    const {axiosi}=useAxios();
    let callInfo_Ticket = useSelector((state) => state.jsSIPReducer.callInfo_Ticket);
    console.log("[TicketNote]:callInfo_Ticket=",callInfo_Ticket);

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleSwitch, setVisibleSwitch] = useState(()=>callInfo_Ticket[id].customerInfo?.length>1);
    
    



    const [historyCallRefresh,setHistoryCallRefresh]=useState(false);
    const [historyTicketRefresh,setHistoryTicketRefresh]=useState(false);

    const [historyCallData,setHistoryCallData]=useState(null);
    const [historyTicketData,setHistoryTicketData]=useState(null);

    const [tel,setTel]=useState(callInfo_Ticket[id].tel);
    const [tkid,setTkid]=useState('');
    const [customerid,setCustomerid]=useState(()=>{
        if (callInfo_Ticket[id].customerInfo?.length===1) return callInfo_Ticket[id].customerInfo[0].id;
        else return '';
    });
    const [customeridInfo,setCustomeridInfo]=useState(null);
    const [customeridInfoRefresh,setCustomeridInfoRefresh]=useState(false);
    const [uniqueid,setUniqueid]=useState('');
    const [recordpath,setRecordpath]=useState('');
    const [autoSave,setAutoSave]=useState(()=>{
        const autoSaveCustomer= localStorage.getItem("autoSaveCustomer");
        console.log("[TicketNote]:autoSaveCustomer=",autoSaveCustomer);
        if (autoSaveCustomer && autoSaveCustomer==="true") {
            return true;
        } else  return false;
    });
    const [autoClose,setAutoClose]=useState(()=>{
        const autoCloseTicketNote= localStorage.getItem("autoCloseTicketNote");
        console.log("[autoCloseTicketNote]:autoCloseTicketNote=",autoCloseTicketNote);
        if (autoCloseTicketNote && autoCloseTicketNote==="true") {
            return true;
        } else  return false;
    });
    const [customer,setCustomer]=useState(()=>{
        if (callInfo_Ticket[id].customerInfo?.length===1) {
            return {
                name:callInfo_Ticket[id].customerInfo[0].name,        
                company:callInfo_Ticket[id].customerInfo[0].ccomname,
                tel:callInfo_Ticket[id].customerInfo[0].tel,
                email:callInfo_Ticket[id].customerInfo[0].email
            }
}
    else {
        return {
            name:'',        
            company:'',
            tel:tel?tel:"",
            email:''
        }
        
    }
});
    const [tn,setTN]=useState({
        title:'',        
        ordercode:'',
        billcode:'',
        cat:'',
        status:'',
        content:'',
        solution:''
    });
    const [ctkid,setCtkid]=useState("New Tiket");
    // const [ctkid,setCtkid]=useState(()=>{
    //     if (tkid)
    //      if (tkid!=='') {
    //          console.log("[TicketNote]:tkid=",tkid)
    //          return tkid 
    //         }
    //      else return "New Tiket";
    //     else return "New Tiket";
    // }
    //     ) ;

    useEffect(()=>{
        const getHistoryCall=async()=>{
            try {
                // return;
                const kq=await axiosi.get('calllogs/getCallLogsHistory',{params:{customer_id:customerid,ctel:callInfo_Ticket[id].tel}});
                console.log("[useEffect-getHistoryCall]:kq.data=",kq.data);
                setHistoryCallData(kq.data.rows);
                
                } catch (err){
                    console.log("ERROR:",err);
                    setHistoryCallData(null);
                }
        }
        getHistoryCall();
    },[customerid,historyCallRefresh])

    useEffect(()=>{
        const getHistoryTicket=async(id)=>{
            try {
                const kq=await axiosi.get('ticketnote',{params:{comcontacts_id:id}});
                console.log("[useEffect-getHistoryCall]:kq.data=",kq.data);
                setHistoryTicketData(kq.data.rows);
                
                } catch (err){
                    console.log("ERROR:",err);
                    setHistoryTicketData(null);
                }
        }
        getHistoryTicket(customerid);
    },[customerid,historyTicketRefresh])

    useEffect(()=>{
        const getCustomerInfo=async(id)=>{
            try {
                const kq=await axiosi.get('comcontacts/id',{params:{id}});
                console.log("[useEffect-getCustomerInfo]:kq.data=",kq.data);
                setCustomeridInfo(kq.data.rows[0]);
                setCustomer({name:kq.data.rows[0].name,
                    company:kq.data.rows[0].ccomname,
                    tel:kq.data.rows[0].tel,
                    email:kq.data.rows[0].email,

                })
                } catch (err){
                    console.log("ERROR")
                }
        }
        getCustomerInfo(customerid);
    },[customerid,customeridInfoRefresh])
    // const [ccustomerid,setCcustomerid]=useState(customerid);
    function callback(key) {
        console.log(key);
        switch(key){
            case "2":
                console.log("2./histroy ticket");
                setHistoryTicketRefresh(pre=>!pre);
                break;
            case "3":
                console.log("3./History Call");
                setHistoryCallRefresh(pre=>!pre);
                break;
            default:
                console.log("n./DEFALUT,key=",key);
                break;
        }
      }
    function handleChange(value) {
        console.log(`selected ${value}`);
      }
    const closeTicket=()=>{
        setMySessionsIdTicket((prev) => prev.filter((item) => id !== item));
        dispatch(delCallInfo_Ticket({ id}));
    }
    const handleCloseTicketNote=()=>{
        closeTicket();
    }
    const addnewcustomer=async()=>{
        try {
            const kq=await axiosi.post("comcontacts",{...customer,ccomname:customer.company,priority_level:3});
            console.log("[addnewcustomer-onFinishAdd]:kq=",kq) 
            setCustomerid(kq.data.rows.insertId);
            return {
                error:"",
                insertId:kq.data.rows.insertId
            } ;
            // setVisibleAdd(false);
            // setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
              return {
                error:"1",
                
            } ;
        }
        return "";
    }
    const editcustomer=async()=>{
        try {
            const kq=await axiosi.put(`comcontacts/FromTicketNote/${customerid}`,{...customer,ccomname:customer.company});
            console.log("[onFinishAdd]:kq=",kq) 
            setCustomeridInfoRefresh(pre=>!pre)
            // setCustomerid(kq.data.rows.insertId);
            // return kq.data.rows.insertId;
            // setVisibleAdd(false);
            // setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
        // return "";
    }
    const  handleSaveTicket=async()=>{
        console.log("[handleSaveTicket]:callInfo_Ticket=",callInfo_Ticket);
        console.log("[handleSaveTicket]:customer=",customer);
        console.log("[handleSaveTicket]:tn=",tn);
        console.log("[handleSaveTicket]:cusomer&tn=",{...customer,...tn,autoSave,autoClose});

        //const kqgetUniqueid= await getUniqueid(id,callInfo_Ticket[id].call_id);

        let comcontacts_id=customerid;
        if (customerid==='') {
            console.log('saving  as new customer !');
            const kqaddnewcustomer= await addnewcustomer();
            console.log('[handleSaveTicket]:kqaddnewcustomer=',kqaddnewcustomer);
            if (kqaddnewcustomer.error==="") {
                console.log('[handleSaveTicket]:1./comcontacts_id=',comcontacts_id);
            comcontacts_id=kqaddnewcustomer.insertId;
            }
            console.log('[handleSaveTicket]:2./comcontacts_id=',comcontacts_id);
        } else {
                if (autoSave) {
                    console.log('update  customer info as a new customer');
                    editcustomer();
                }
            }
        console.log('[handleSaveTicket]:3./comcontacts_id=',comcontacts_id);
        const callinfo={
            uniqueid:callInfo_Ticket[id].mysessionInfo.uniqueid[0].raw,
            recordpath:callInfo_Ticket[id].mysessionInfo.recordpath[0].raw,
            calltype:callInfo_Ticket[id].mysessionInfo.calltype[0].raw,
        }
        const datatk={...customer,...tn,ccomname:customer.company,comcontacts_id,
            ctel:callInfo_Ticket[id].tel, ...callinfo};
        console.log("[handleSaveTicket]:datatk=",datatk);
        
        // save tiket        
        //==> set tiketid

        try {
            const kq=await axiosi.post("ticketnote",datatk);
            console.log("[handleSaveTicket-onFinishAdd]:kq=",kq) 
            setCtkid(kq.data.rows.insertId);
            const today=new Date();
            message.info({
                content: `[${today.toLocaleTimeString()}]: saved ! `,
                className: 'custom-class',
                style: {
                  position: "absolute",
                  right:"5px",
                },
              });
            
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);             
        }
        // return "";
        if (autoClose)  {
            closeTicket();
        } else {
            //set tiketid==> go to edit mode
        }
        // return;
       
        
    }
    const  handleSaveTicketUpdate=async()=>{
        // console.log("[handleSaveTicketUpdate]:callInfo_Ticket=",callInfo_Ticket);
        // console.log("[handleSaveTicket]:customer=",customer);
        // console.log("[handleSaveTicket]:tn=",tn);
        // console.log("[handleSaveTicket]:cusomer&tn=",{...customer,...tn,autoSave,autoClose});

        // const kqgetUniqueid= await getUniqueid(id,callInfo_Ticket[id].call_id);

        let comcontacts_id=customerid;
        // if (customerid==='') {
        //     console.log('saving  as new customer !');
        //     const kqaddnewcustomer= await addnewcustomer();
        //     console.log('[handleSaveTicket]:kqaddnewcustomer=',kqaddnewcustomer);
        //     if (kqaddnewcustomer.error==="") {
        //         console.log('[handleSaveTicket]:1./comcontacts_id=',comcontacts_id);
        //     comcontacts_id=kqaddnewcustomer.insertId;
        //     }
        //     console.log('[handleSaveTicket]:2./comcontacts_id=',comcontacts_id);
        // }
        if (customerid!=='' && autoSave) {
            console.log('update  customer info as a new customer');
            editcustomer();
        }
        // console.log('[handleSaveTicket]:3./comcontacts_id=',comcontacts_id);
        const datatk={...customer,...tn,ccomname:customer.company,comcontacts_id};
        console.log("[handleSaveTicketUpdate]:datatk=",datatk);
        
        // save tiket        
        //==> set tiketid

        try {
            const kq=await axiosi.put(`ticketnote/${ctkid}`,datatk);
            console.log("[handleSaveTicketUpdate-onFinishAddUpdate]:kq=",kq) 
            // setCtkid(kq.data.rows.insertId);
            const today=new Date();
            // console.log(".............: ",`[${today.toLocaleTimeString()}]: saved ! `);
            // message.info('hello');
            // message.info(`[${today.toLocaleTimeString()}]: saved ! `);
            message.info({
                content: `[${today.toLocaleTimeString()}]: saved ! `,
                className: 'custom-class',
                style: {
                  position: "absolute",
                  right:"5px",
                },
              });
            
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);             
        }
        // return "";
        if (autoClose)  {
            closeTicket();
        } else {
            //set tiketid==> go to edit mode
        }
        // return;
       
        
    }
    const getUniqueid = async (id, call_id) => {
        try {
          const kq = await axiosi.get("callstatus/cdrout", { params: { call_id } });
          const kqdata = await kq.data;
          console.log("[TicketNote-getUniqueid]:kqdata=",kqdata);
          // console.log("[getCustomerInfoByTel]:kqdata.rows=",kqdata.rows);
    
          if (kqdata.rows.length > 0) {
            setUniqueid(kqdata.rows[0]["uniqueid"]);
            setRecordpath(kqdata.rows[0]["recordpath"]);
            return {
                error:"",
                data:kqdata.rows[0],
                // uniqueid: kqdata.rows[0]["uniqueid"],
                // recordpath: kqdata.rows[0]["recordpath"],
                // calltype: kqdata.rows[0]["calltype"]
            }
            // mysessionsInfo.current[id].uniqueid = kqdata.rows[0]["uniqueid"];
            // mysessionsInfo.current[id].recordpath = kqdata.rows[0]["recordpath"];
          } else {
            setUniqueid("");
            setRecordpath("");
            return {
                error:"0",             
            }
            // mysessionsInfo.current[id].uniqueid = "";
            // mysessionsInfo.current[id].recordpath = "";
          }
        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("error.response.data=", error.response.data);
            console.log("error.response.status=", error.response.status);
            console.log("error.response.headers=", error.response.headers);
            // message.error(`Error: ${error.response.data.code}`);
            setUniqueid("");
            setRecordpath("");
            // mysessionsInfo.current[id].uniqueid = "";
            // mysessionsInfo.current[id].recordpath = "";
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            setUniqueid("");
            setRecordpath("");
            // mysessionsInfo.current[id].uniqueid = "";
            // mysessionsInfo.current[id].recordpath = "";
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            setUniqueid("");
            setRecordpath("");
            // mysessionsInfo.current[id].uniqueid = "";
            // mysessionsInfo.current[id].recordpath = "";
          }
          console.log(error.config);
          setUniqueid("");
            setRecordpath("");
            return {
                error:"1",                               
            }
          // mysessionsInfo.current[id].uniqueid = "";
          // mysessionsInfo.current[id].recordpath = "";
        }
      };
    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }
    const onCloseEdit=()=>{
        console.log("[onCloseEdit]")
        // setSelectedItem(null);
        setVisibleEdit(false);
    }
    const onCloseSwitch=()=>{
        setVisibleSwitch(false);
    }
    const handleSwitch=()=>{
        console.log("[handleSwitch]:");
        setVisibleSwitch(true);
    }
    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const handleEdit=async()=>{
        
        console.log("[handleEdit]:customerid",customerid);
        console.log("[handleEdit]:customeridInfo",customeridInfo);
        setVisibleEdit(true);
        // try {
        //     const kq=await axiosi.get('comcontacts/id',{params:{id:customerid}});
        //     console.log("[handleEdit-getCustomerInfo]:kq.data=",kq.data.rows[0]);
        //     setCustomeridInfo(kq.data.rows[0]);
        //     } catch (err){
        //         console.log("ERROR")
        //     }
    }


    const handleAutoSave=(e)=>{
        localStorage.setItem("autoSaveCustomer",e.target.checked);
        setAutoSave(e.target.checked);
    }
    
    const handleAutoClose=(e)=>{
        localStorage.setItem("autoCloseTicketNote",e.target.checked);
        setAutoClose(e.target.checked)
    }

    const handleCopyTKID=()=>{
        navigator.clipboard.writeText(ctkid);
        message.info({
            content: 'copied !',
            className: 'custom-class',
            style: {
              position: "absolute",
              right:"5px",
            },
          });
    }
  return (
      <>
      {console.log("[return-TicketNote]:customeridInfo=",customeridInfo)}
      {visibleAdd &&
      (<Drawer title="Add  a new Customer :" 
            width={640}
            placement="right" onClose={onCloseAdd} visible={visibleAdd}>             
                    <AddCustomer 
                    // setRefressSearch={setRefressSearch}
                     setVisibleAdd={setVisibleAdd}
                        provinceData={provinceData}

                        customer={customer}
                        setCustomer={setCustomer}
                        setCustomerid={setCustomerid}
                        tel={tel}
                    />        
        </Drawer> )}

        {visibleEdit && (
        <Drawer width={640} title="Edit Customer :" placement="right" 
        onClose={onCloseEdit} visible={visibleEdit}>
          <EditCustomer
           provinceData={provinceData} 
           item={customeridInfo} 
           setCustomer={setCustomer}
           setCustomeridInfoRefresh={setCustomeridInfoRefresh}
        //    setSelectedItem={setSelectedItem}
        //     setRefressSearch={setRefressSearch} 
            setVisibleEdit={setVisibleEdit} />    
      </Drawer >
      )}
      {visibleSwitch && (
        <Drawer width={1040} title="Please select a customer !" placement="right" 
            visible={visibleSwitch} onClose={onCloseSwitch}>
            <SwitchCustomer setCustomerid={setCustomerid} setVisibleSwitch={setVisibleSwitch} ctel={tel} />
        </Drawer>
        )}
      <Draggable nodeRef={nodeRef}
        // axis="x"
        handle=".moveable .TiketNote_header"
        defaultPosition={{x: 0, y: 0}}
        position={null}
        grid={[25, 25]}
        scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
        >



    <div className='TiketNote moveable' ref={nodeRef}>
        <div className={"btnclose"} title="Close" onClick={handleCloseTicketNote}><CloseSquareFilled /></div>
        <div className="TiketNote_header ">
            
               <h3>Ticket Note : </h3>
               <h5 onClick={handleCopyTKID}> {`#tkid: ${ctkid}`}</h5>
        </div>
        <div className="TiketNote_body">
        <Tabs  defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Ticket" key="1">
                <div className="container">
                    <div className="TiketNote_customer box">
                        <div className='boxtitle'>
                            <h3>Customer : </h3>
                            <div className="boxtitle_edit">
                               
                           {(customerid==='')?(<Button type="link" title="Add a new customer !" onClick={handleAdd} >Add</Button>):(<Button type="link" title="Edit customer !" onClick={handleEdit} >Edit</Button>)} 
                            <Button type="link" title="Change to another customer !" onClick={handleSwitch} >Switch</Button>
                            </div>
                        </div>
           
                        <div className="row">

                            <div className="form-field">
                                <input type="text" value={customer.name} onChange={(e)=>setCustomer(prev=>({...prev,name:e.target.value}))} className='form-input' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Name:</label>
                            </div>
                            <div className="form-field">
                                <input type="text" value={customer.company} onChange={(e)=>setCustomer(prev=>({...prev,company:e.target.value}))} className='form-input' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Company:</label>
                            </div>
                            <div className="form-field">
                                <input type="text" value={customer.tel} onChange={(e)=>setCustomer(prev=>({...prev,tel:e.target.value}))} className='form-input' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Tel:</label>
                            </div>
                            <div className="form-field">
                                <input type="text" value={customer.email} onChange={(e)=>setCustomer(prev=>({...prev,email:e.target.value}))} className='form-input' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Email:</label>
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="TiketNote_Ticket box">
                        <h3 className='boxtitle'>Note :</h3>
                        <div className="row">

                            <div className="form-field">
                                <input type="text" value={tn.title} onChange={(e)=>setTN(prev=>({...prev,title:e.target.value}))} className='form-input' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Title:</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-field">
                                <input type="text" value={tn.ordercode} onChange={(e)=>setTN(prev=>({...prev,ordercode:e.target.value}))} className='form-input' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Order code:</label>
                            </div>
                            <div className="form-field">
                                <input type="text" value={tn.billcode} onChange={(e)=>setTN(prev=>({...prev,billcode:e.target.value}))} className='form-input' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Bill code:</label>
                            </div>
                            <div className="form-field">
                                {/* <input type="text" className='form-input' placeholder=' ' /> */}
                                <Select placeholder=' ' value={tn.cat} onChange={(value)=>setTN(prev=>({...prev,cat:value}))} className='form-input form-select' defaultValue=""  >
                                    <Option value=""></Option>
                                    <Option value="Ask">Ask</Option>                                
                                    <Option value="Complaint">Complaint</Option>
                                    <Option value="Others">Others</Option>
                                </Select>
                                <label htmlFor="" className='form-label'>Category:</label>
                            </div>
                            <div className="form-field">
                                {/* <input type="text" className='form-input' placeholder=' ' /> */}
                                <Select placeholder=' ' value={tn.status} onChange={(value)=>setTN(prev=>({...prev,status:value}))}  className='form-input form-select' defaultValue="New"  >
                                    
                                    <Option value="New">New</Option>
                                    <Option value="Processing">Processing</Option>
                                    <Option value="Successful">Successful</Option>                                
                                    <Option value="Failed">Failed</Option>
                                </Select>
                                <label htmlFor="" className='form-label'>Status:</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-field">
                                <textarea  rows="4" value={tn.content} onChange={(e)=>setTN(prev=>({...prev,content:e.target.value}))} type="text" className='form-input form-textarea' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Content:</label>
                            </div>
                            <div className="form-field">
                                <textarea rows="4" value={tn.solution} onChange={(e)=>setTN(prev=>({...prev,solution:e.target.value}))} type="text" className='form-input form-textarea' placeholder=' ' />
                                <label htmlFor="" className='form-label'>Solution:</label>
                            </div>
                        </div>
                        
                    </div>
                
              

            </div>
            <div className="TiketNote_footer">
            <div className='footer_left'>
                {customerid!=="" && <Checkbox defaultChecked={autoSave} onChange={handleAutoSave}>Auto update this customer infomation</Checkbox>}
                 
                 <Checkbox defaultChecked={autoClose} onChange={handleAutoClose}>Auto close after saved</Checkbox>
             </div>
             <div className='footer_right'>                 
                 {(ctkid==="New Tiket")?(<Button type="primary" onClick={handleSaveTicket}>Save</Button>):(<Button type="primary" onClick={handleSaveTicketUpdate}>Save</Button>)}                      
             </div> 
             
        </div>
    </TabPane>
    <TabPane tab="History Tickets" key="2">
    {historyTicketData && <ShowTicketNoteTable data={historyTicketData} setRefressSearch={setHistoryTicketRefresh}/>}
    </TabPane>
    <TabPane tab="History Calls" key="3">
    {historyCallData && <ShowCallLogTable data={historyCallData}/>}
    {/* {historyCallData && "<p>co history CallData</p>"} */}
    </TabPane>
    
        </Tabs>
  
            
        </div>
        
    </div>
    </Draggable>
    </>
  )
}
